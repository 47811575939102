import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { ApiUrlInterceptorService } from './interceptors/api-url-interceptor.service';
import { WINDOW_PROVIDERS } from './services/window/window.service';
import { OfflineService, initializeOfflineService } from './services/offline/offline.service';

@NgModule({
  imports: [ HttpClientModule ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptorService,
      multi: true
    },
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeOfflineService,
      deps: [ OfflineService ],
      multi: true
    }
  ]
})
export class CoreModule { }
