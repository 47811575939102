import {
  HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { AuthState } from '../state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private toast: HotToastService,
    private authSvc: AuthService,
    private authState: AuthState,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.authState.get('token');

    if (token) {
      req = this.addAuthToken(req, token);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (req.url.includes('login') || req.url.includes('isValidPassword')) {
          return throwError(() => error);
        }

        if (error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          console.error(error.message, error.statusText);
          let errorMsg = error.message;
          if (error?.error?.message) {
            errorMsg = error.error.message;
          }
          this.toast.error(`${error.statusText}: ${errorMsg}`);

          return throwError(() => error);
        }
      })
    );
  }

  private addAuthToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url === 'tokenRefresh') {
      this.authSvc.removeTokens();
      this.router.navigateByUrl('/');
    }
    //  else if (this.authState.get('refreshToken') && !this.authState.get('tokenRefreshing')) {
    //   console.info('refreshing token from 401');
    //   return this.authSvc.refreshToken()
    //     .pipe(
    //       switchMap((tokens: any) => {
    //         const { token } = tokens;
    //         return next.handle(this.addAuthToken(req, token));
    //       })
    //     );
    // }

    return next.handle(req);
  }

}
