import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot 
} from '@angular/router';
import { isNull } from 'lodash';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthState } from '../../../auth/state/auth.state';

import { AuthService } from '../../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authSvc: AuthService,
    private authState: AuthState,
    private router: Router
  ) { }

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return this.authState.isLoggedIn$
      .pipe(
        first(isLoggedIn => !isNull(isLoggedIn)),
        map(isLoggedIn => {
          if (this.authSvc.isAuthenticated()) {
            return true;
          }

          if (![ '/login', '/' ].includes(state.url)) {
            sessionStorage.setItem('redirect', state.url);
          }

          this.authSvc.removeTokens();

          this.router.navigateByUrl('/login');

          return false;
        })
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.canActivate();
  }
}
