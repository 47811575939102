import {
  ChangeDetectionStrategy, Component, Inject, Injector, OnInit, Optional, ViewEncapsulation 
} from '@angular/core';
import { SidenavLinksProvider } from '~core/tokens/sidenav-links';
import { MAIN_NAV_LINKS } from '../../config/nav-links';

@Component({
  templateUrl: './sidenav-menu.component.html',
  styleUrls: [ './sidenav-menu.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SidenavMenuComponent implements OnInit {

  constructor(@Optional() @Inject(SidenavLinksProvider) public links: any[]) { }

  ngOnInit(): void {
    if (!this.links) {
      this.links = MAIN_NAV_LINKS;
    }
  }

}
