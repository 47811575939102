import {
  Inject, Injectable, OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';

import { WINDOW } from '../window/window.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService implements OnDestroy {

  private internalConnectionChanged = new Subject<boolean>();

  private updateFn: () => void;

  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }

  get isOnline() {
    return !!this.window.navigator.onLine;
  }

  constructor(
    @Inject(WINDOW) private window: Window
  ) {}

  init() {
    this.updateFn = () => this.updateOnlineStatus();
    this.window.addEventListener('online', this.updateFn.bind(this));
    this.window.addEventListener('offline', this.updateFn.bind(this));
  }

  private updateOnlineStatus() {
    console.log('this.isOnline', this.isOnline);
    this.internalConnectionChanged.next(this.isOnline);
  }

  ngOnDestroy() {
    this.window.removeEventListener('online', this.updateFn);
    this.window.removeEventListener('offline', this.updateFn);
  }
}

export function initializeOfflineService(offlineSvc: OfflineService): () => void {
  return () => offlineSvc.init();
}
