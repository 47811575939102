import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import {
  combineLatest, Observable, of 
} from 'rxjs';
import {
  distinctUntilChanged, filter, pluck, switchMap, take, tap 
} from 'rxjs/operators';
import { AppState, AppStateKey } from '~core/states/app/app.state';
import { User } from '../../../shared/models/user.model';
import { STILUserDataService } from '../../../shared/services/apiSTILUserController';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private state: AppState,
    private userDataSvc: STILUserDataService
  ) { }

  initAppState(): Observable<User> {
    /* Sets facilityId in app state when facility changes */
    this.state.facility$
      .pipe(
        tap(({ Name }) => this.state.set('facilityName', Name)),
        pluck('ID'),
        distinctUntilChanged()
      )
      .subscribe(ID => {
        this.state.set('facilityId', +ID);
        localStorage.setItem('lastFacilityId', ID);
      });

    /* Updates user's ParentID in app state when facilityId changes */
    return combineLatest([ this.state.facility$, this.state.user$ ])
      .pipe(
        filter(([ facility, user ]) => !isEmpty(facility) && !isEmpty(user)),
        switchMap(([ facility, user ]) => {
          if (+user.ParentID !== +facility.Company_ParentID) {
            return this.userDataSvc.edit(user);
          }

          return of(user);
        }),
        take(1)
      );
  }

  loadsessionStorage(): void {
    [ 'facility', 'user' ]
      .forEach((key: AppStateKey) => {
        const value = sessionStorage.getItem(key);

        if (value) {
          this.state.set(key, JSON.parse(value));
        }
      });
  }
}

export function InitializeAppState(appSvc: AppService): () => void {
  return () => appSvc.loadsessionStorage();
}

