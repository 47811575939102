import { Injectable, Injector } from '@angular/core';
import {
  NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent 
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppState } from '~core/states/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
    private appState: AppState,
    private router: Router
  ) { }

  init(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart)
      )
      .subscribe(() => this.appState.set('loading', true));

    this.router.events
      .pipe(
        filter((event: RouterEvent) => (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ))
      )
      .subscribe(() => this.appState.set('loading', false));
  }
}

export function InitializeLoading(injector: Injector): () => void {
  return () => {
    const loadingSvc = injector.get(LoadingService);
    loadingSvc.init();
  };
}
