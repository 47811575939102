import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation 
} from '@angular/core';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '~auth/services/auth.service';
import { InactivityService } from 'src/app/auth/services/inactivity/inactivity.service'

@Component({
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {

  mobileQuery$ = this.breakpointObserver
    .observe([ '(max-width: 675px )' ])
    .pipe(map(result => result.matches));

  sidenavMode$ = this.mobileQuery$.pipe(map(isMobile => isMobile ? 'over' : 'side'));

  constructor(
    private authSvc: AuthService,
    private breakpointObserver: BreakpointObserver,
    private inactivitySvc: InactivityService
  ) { }

  ngOnInit(): void {
    this.inactivitySvc.startWatch();
  }

  logout(): void {
    lastValueFrom(this.authSvc.logout());
  }
}
