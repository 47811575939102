import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CreateHotToastRef, HotToastService } from '@ngneat/hot-toast';
import { DateTime } from 'luxon';
import {
  fromEvent, interval, Subscription, timer 
} from 'rxjs';
import {
  filter, startWith, switchMap, takeUntil, throttle 
} from 'rxjs/operators';
import { AuthState } from '~auth/state/auth.state';
import { FacilityState } from '~core/states/facility/facility.state';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private loggedOut$ = this.authState.isLoggedIn$.pipe(filter(loggedIn => !loggedIn));

  private mousemoveSub: Subscription;

  private toastRef: CreateHotToastRef<any>;

  get inactivityTimeoutMillis(): number {
    let inactivityTimeoutMins = this.facilityState.get('generalSettings')?.loginInactivityTimeout;

    if (isNaN(+inactivityTimeoutMins)|| +inactivityTimeoutMins < 1) {
      inactivityTimeoutMins = 10; // Default inactivity timeout to 10 minutes;
    }

    return inactivityTimeoutMins * 60 * 1000;
  }

  constructor(
    private authState: AuthState,
    private authSvc: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private facilityState: FacilityState,
    private toast: HotToastService
  ) { }

  startWatch(): void {
    if (this.toastRef) {
      this.toastRef.close();
    }

    this.authState.lastActiveTime$
      .pipe(
        switchMap(lastActiveTime => {
          const inactiveDateTime = DateTime.fromMillis(lastActiveTime + this.inactivityTimeoutMillis).toJSDate();

          return timer(inactiveDateTime);
        }),
        takeUntil(this.loggedOut$),
        switchMap(() => this.authSvc.logout())
      )
      .subscribe(() => {
        this.toastRef = this.toast.warning(`You have been logged out due to inactivity`, {
          autoClose: false,
          dismissible: true 
        });
      });

    if (this.mousemoveSub instanceof Subscription) {
      this.mousemoveSub.unsubscribe();
    }

    this.mousemoveSub = fromEvent(this.document, 'mousemove')
      .pipe(
        startWith('initialEvent'),
        throttle(() => interval(1000)),
        takeUntil(this.loggedOut$)
      )
      .subscribe(() => this.authState.set('lastActiveTime', DateTime.local().toMillis()));
  }
}
