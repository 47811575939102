import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { HttpCacheManager } from '@ngneat/cashew';
import { HotToastService } from '@ngneat/hot-toast';
import { find } from 'lodash';
import { AppState } from '../../../core/states/app/app.state';
import { STILFacilityDataService } from '../../../shared/services/apiSTILFacilityController';
import { STILGlobalDataDataService } from '../../../shared/services/apiSTILGlobalDataController';

import { lastValueFrom } from 'rxjs';

export interface LoginPageData {
  connectionStringNames: string[];
  defaultConnectionStringName: string;
  facilities: any[];
  defaultFacility: any[];
}


@Injectable({
  providedIn: 'root'
})
export class LoginPageResolve implements Resolve<Promise<LoginPageData>> {

  constructor(
    private appState: AppState,
    private stilGlobalDataSvc: STILGlobalDataDataService,
    private facilityDataSvc: STILFacilityDataService,
    private cacheManager: HttpCacheManager,
    private toast: HotToastService
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<LoginPageData> {
    this.appState.set('loading', true);
    /* Get connection strings */
    const connectionStringNames = await lastValueFrom(this.stilGlobalDataSvc.getConnectionStringList());

    if (!connectionStringNames.length) {
      this.toast.error('No ConnectionString names were found. There must be an issue.');
    }

    this.cacheManager.delete('permit-facility-settings');
    this.cacheManager.delete('facility-settings');

    const connectionStringName = localStorage.getItem('lastConnectionStringName');
    const defaultConnectionStringName = find(connectionStringNames, name => name === connectionStringName) || connectionStringNames[0];

    /* Get facilities */
    const facilities = await lastValueFrom(this.facilityDataSvc.getRecords(true, defaultConnectionStringName));

    if (!facilities.length) {
      this.toast.error('No Facilities were found for the default connection string name. Please try another connection string');

    }

    const facilityId = sessionStorage.getItem('facilityId') || localStorage.getItem('lastFacilityId');

    const defaultFacility = find(facilities, { ID: +facilityId }) ?? facilities[0];

    this.appState.set('loading', false);

    return ({
      connectionStringNames,
      defaultConnectionStringName,
      defaultFacility,
      facilities
    });
  }
}
