<mat-nav-list id="sidenav-menu">
  <mat-list-item
    *ngFor="let link of links"
    class="sidenav-menu-item"
    [routerLink]="link?.route"
    [attr.aria-label]="link?.description"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="active"
  >
    <mat-icon matListIcon>
      {{ link?.icon }}
    </mat-icon>

    <p matLine>{{ link?.name }}</p>
  </mat-list-item>
</mat-nav-list>
