import { Injectable } from '@angular/core';
import {
  State, StateBehavior, StateReplay, StateSubject 
} from '@devlime/ngx-state';
import { Observable } from 'rxjs';
import { User } from '~models/user';

export interface AuthStateData {
  readonly exp: number;
  readonly expRefresh: number;
  readonly isLoggedIn: boolean;
  readonly lastActiveTime: number;
  readonly refreshToken: string;
  readonly token: string;
  readonly tokenRefreshing: boolean;
}

type AuthStateKey = keyof AuthStateData;

@Injectable({
  providedIn: 'root'
})
export class AuthState extends State<AuthStateData> {

  @StateReplay<AuthState, number>()
  private exp: number;
  readonly exp$: Observable<number>;

  @StateReplay<AuthState, number>()
  private expRefresh: number;
  readonly expRefresh$: Observable<number>;

  @StateReplay<AuthState, boolean>()
  private isLoggedIn: boolean;
  readonly isLoggedIn$: Observable<boolean>;

  @StateSubject<AuthState, number>()
  private lastActiveTime: number;
  readonly lastActiveTime$: Observable<number>;

  @StateReplay<AuthState, string>()
  private refreshToken: string;
  readonly refreshToken$: Observable<string>;

  @StateReplay<AuthState, string>()
  private token: string;
  readonly token$: Observable<string>;

  @StateBehavior<any, boolean>(false)
  private tokenRefreshing: boolean;
  readonly tokenRefreshing$: Observable<boolean>;

  constructor() {
    super();
  }

  set(prop: AuthStateKey, value: AuthStateData[AuthStateKey]): void {
    super.set(prop, value);

    if (value) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      sessionStorage.setItem(prop, `${value}`);
    }
  }
}
