import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit 
} from '@angular/core';
import {
  AbstractControl, FormBuilder, FormGroup, Validators 
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { HotToastService } from '@ngneat/hot-toast';
import { AppState } from '../../../core/states/app/app.state';
import { STILFacilityDataService } from '../../../shared/services/apiSTILFacilityController';
import { find } from 'lodash';



@UntilDestroy()
@Component({
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  connectionStringNames: any[];
  facilities: any[];
  filteredUsers;
  loginForm: FormGroup;
  loading = false;
  returnUrl: string;
  showInvalidCredsMsg = false;
  submitted = false;

  get connectionStringNameCtrl(): AbstractControl {
    return this.loginForm.get('connectionStringName');
  }

  get facilityIdCtrl(): AbstractControl {
    return this.loginForm.get('facilityId');
  }

  get passwordCtrl(): AbstractControl {
    return this.loginForm.get('password');
  }

  get usernameCtrl(): AbstractControl {
    return this.loginForm.get('username');
  }

  constructor(
    private appState: AppState,
    private authSvc: AuthService,
    private formBuilder: FormBuilder,
    private facDataSvc: STILFacilityDataService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toast: HotToastService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      connectionStringName: [ '', Validators.required ],
      facilityId: [ '', Validators.required ],
      username: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });

    this.connectionStringNameCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((connectionStringName: string) => this.facDataSvc.getRecords(true, connectionStringName))
      )
      .subscribe((facilities) => {
        this.facilities = facilities;

        if (facilities.length) {
          const defaultFacility = facilities[0];
          this.loginForm.patchValue({ facilityId: defaultFacility.ID });
          this.cdr.markForCheck();
        } else {
          this.toast.error('No Facilities were found. Please try another Connection String Name.');
        }
      });



    this.passwordCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(pw => this.showInvalidCredsMsg = false);

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(({
        loginPageData: {
          connectionStringNames, defaultConnectionStringName, defaultFacility, facilities, users 
        } 
      }) => {
        this.connectionStringNames = connectionStringNames;
        this.facilities = facilities;

        this.loginForm.patchValue({
          connectionStringName: defaultConnectionStringName,
          facilityId: +defaultFacility.ID
        }, { emitEvent: false });
      });
  }

  login(): void {
    /*
    this.authSvc.login(this.loginForm.value).toPromise()
      .then(() => this.router.navigateByUrl('/portal'))
      .catch(err => console.error('error:', err));
    */

    const {
      connectionStringName, facilityId, password, username 
    } = this.loginForm.value;
    this.submitted = true;
    this.loading = true;

    const selectedFacility = find(this.facilities, { ID: +facilityId });

    if (this.loginForm.invalid || !username) {
      this.loginForm.patchValue({ password: '' });
      this.toast.error('Invalid user for this facility');
      this.loading = false;
      return;
    }

    this.authSvc.login(connectionStringName, facilityId, username, password)
      .subscribe(authObj => {
        if (authObj) {
          sessionStorage.setItem('redirect','portal');
          this.appState.set('facility', selectedFacility);
          this.showInvalidCredsMsg = false;
          const { user } = authObj;
          this.appState.set('user', user);
          this.authSvc.setSession(authObj);
          this.authSvc.redirectUser();
        } else {
          this.resetLoginForm();
        }
      });
  }
  private resetLoginForm(): void {
    this.loginForm.patchValue({
      username: '',
      password: '' 
    });
    this.submitted = false;
    this.loading = false;
    this.showInvalidCredsMsg = true;
    this.cdr.markForCheck();
  }

}
