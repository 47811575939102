import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Inject, Injectable, Injector 
} from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import {
  Observable, of, Subscription, timer, lastValueFrom 
} from 'rxjs';
import {
  catchError, switchMap, take, takeWhile, tap 
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthState } from '../state/auth.state';

import { WINDOW } from '../../core/services/window/window.service';
import { LoginControllerService } from '../../shared/services/app-services/apiLoginAppController';
import { AppState } from '~core/states/app/app.state';
import { PermissionsState } from '../../permissions/state/permissions.state';
import { castArray } from 'lodash';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { PasswordResetModalComponent } from '../password-reset-modal/password-reset-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  refreshTimerSub: Subscription;

  constructor(
    private appState: AppState,
    private loginSvc: LoginControllerService,
    private router: Router,
    private state: AuthState,
    private dialog: MatDialog,
    private toast: HotToastService,
    private permissionsState: PermissionsState,
    @Inject(WINDOW) private window: Window
  ) {}

  getUserRoles() {
    return [ 'admin', 'user' ];
  }

  isAuthenticated(): boolean {
    return this.state.get('isLoggedIn');
  }

  init() {
    if (!navigator.cookieEnabled) {
      this.toast.warning('Access Denied to sessionStorage, try enabling Cookies');
    }

    const navigationType = (this.window.performance.getEntriesByType("navigation")?.[0] as any)?.type;

    if (navigationType == 'back_forward'){
      this.removeTokens();
    }

    const exp = +sessionStorage.getItem('exp');
    const token = sessionStorage.getItem('token');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const user = sessionStorage.getItem('user');

    if (user && token) {
      this.state.set('exp', +exp);
      this.state.set('token', token);
      this.state.set('refreshToken', refreshToken);

      lastValueFrom(this.refreshToken(refreshToken));
    } else {
      this.removeTokens();
    }
  }

  isAuthorized(accessRoles: string | string[] = []) {
    accessRoles = castArray(accessRoles);

    return true;
  }

  login(connectionStringName: string, facilityId: number, username: string, password: string): Observable<any> {
    console.info('Login call started...');
    return this.loginSvc.login(connectionStringName, facilityId, username, password)
      .pipe(
        tap(() => localStorage.setItem('lastConnectionStringName', connectionStringName)),
        catchError(error => {
          console.error(`Login Error: ${error?.message}`);
          this.toast.error('Authorization Failed');
          return of(false);
        })
      );
  }

  logout(): Observable<boolean> {
    console.log('logout call initiated...');

    return this.loginSvc.logout()
      .pipe(
        switchMap(() => {
          this.removeTokens();
          console.log(`logout call finished at ${DateTime.local().toJSDate()}.\n Redirecting to login...`);
          return this.router.navigateByUrl('/login');
        }),
        catchError(err => {
          this.removeTokens();
          console.warn(`logout call FAILED at ${DateTime.local().toJSDate()}.\n Redirecting to login...`);
          return this.router.navigateByUrl('/login');
        })
      );
  }

  redirectUser(): void {
    console.log('redirecting user...');
    const redirect = sessionStorage.getItem('redirect');

    if (redirect) {
      this.router.navigateByUrl(redirect);
      sessionStorage.removeItem('redirect');
    } else {
      this.router.navigateByUrl('/');
    }
  }

  removeTokens() {
    /* TODO: replace sessionStorage with InjectionToken */
    sessionStorage.clear();
    this.permissionsState.set('allPermissions', null);
    this.state.set('exp', null);
    this.state.set('token', null);
    this.state.set('refreshToken', null);
    this.appState.set('user', null);
    this.state.set('isLoggedIn', false);
  }

  setSession({
    exp, expRefresh, token, refreshToken 
  }: any): void {
    if (exp && expRefresh && token && refreshToken) {
      this.state.set('exp', +exp);
      this.state.set('expRefresh', +expRefresh);
      this.state.set('token', token);
      this.state.set('refreshToken', refreshToken);
      this.state.set('isLoggedIn', true);
      this.setRefreshTimer();
      this.state.set('tokenRefreshing', false);
    } else {
      this.removeTokens();
      this.toast.error(`Attempted to set invalid authentication token`);
    }
  }

  refreshToken(refreshToken = this.state.get('refreshToken')): Observable<any> {
    this.state.set('tokenRefreshing', true);

    return this.loginSvc.refreshToken(refreshToken)
      .pipe(
        tap(this.setSession.bind(this))
      );
  }

  openResetPasswordModal(): Observable<boolean> {
    const dialogOptions = {
      width: '640px',
      hasBackdrop: true
    };

    return this.dialog.open(PasswordResetModalComponent, dialogOptions)
      .afterClosed();
  }


  private setRefreshTimer(): void {
    const tokenExpirationDateTime = DateTime.fromSeconds(this.state.get('exp')).toJSDate();

    if (this.refreshTimerSub) {
      console.debug('refresh timer existed, unsubscribing');
      this.refreshTimerSub.unsubscribe();
    }

    console.debug(`User Token refreshed at: ${DateTime.local().toFormat('hh:mm:ss a ZZZZ') }`);
    console.debug(`User Token will expire at: ${DateTime.fromJSDate(tokenExpirationDateTime).toFormat('hh:mm:ss a ZZZZ')}`);

    this.refreshTimerSub = timer(tokenExpirationDateTime)
      .pipe(
        takeWhile(() => this.state.get('isLoggedIn') === true),
        switchMap(() => this.refreshToken()),
        take(1)
      )
      .subscribe(() => console.debug('Token Refreshed'));
  }
}

export function InitializeAuth(injector: Injector): () => void {
  return () => {
    const authSvc = injector.get(AuthService);
    authSvc.init();
  };
}

