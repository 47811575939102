export const MAIN_NAV_LINKS = [
  {
    description: 'Portal Management',
    name: 'Portal Management',
    icon: 'storage',
    route: '/portal/management'
  },
  {
    description: 'Database Management',
    name: 'DB Manage',
    icon: 'storage',
    route: '/portal/db-manage'
  },
  {
    description: 'Services',
    name: 'Services',
    icon: 'settings_input_component',
    route: '/portal/worker-services/getkeys'
  },
  {
    description: 'Reports',
    name: 'Reports',
    icon: 'pages',
    route: '/portal/reports-upload/upload'
  }
];

