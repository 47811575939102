<div class="f-col center-center f-grow h-100 w-100 bg-dark text-white">
  <form
    class="f-row wrap center-center w-40"
    [formGroup]="loginForm"
  >
    <div class="mb-3 text-center w-100">
      <div
        id="logo"
        class="mb-2 w-100"
      ></div>
      <div class="text-primary display-3">Stilwell Portal</div>
    </div>

    <div
      *ngIf="showInvalidCredsMsg"
      class="form-group mb-1"
      style="color: red"
    >
      <p>Invalid Credentials. Please try again.</p>
    </div>

    <div class="mb-3 w-100">
      <label
        for="facilityId"
        class="form-label"
      >
        Facility
      </label>
      <select
        class="form-select"
        aria-label="Facility Selector"
        formControlName="facilityId"
      >
        <option
          *ngFor="let facility of facilities"
          [value]="facility.ID"
        >
          {{ facility.Name }}
        </option>
      </select>
    </div>

    <div class="mb-3 w-100">
      <label
        for="username"
        class="form-label"
      >
        Username
      </label>
      <input
        type="text"
        class="form-control"
        id="username"
        formControlName="username"
      />
    </div>

    <div class="mb-3 w-100">
      <label
        for="password"
        class="form-label"
      >
        Password
      </label>
      <input
        type="password"
        class="form-control"
        id="password"
        formControlName="password"
      />
    </div>

    <div class="f-row end-center w-100">
      <button
        class="btn btn-primary"
        (click)="login()"
        [disabled]="loginForm.invalid"
      >
        Login
      </button>
    </div>
  </form>
</div>
