
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class STILFacilityDataService {

  constructor(private http: HttpClient) {}

  view(facilityID: number) {
    const params = { facilityID };
    return this.http.get(`stilFacility/view`);
  }
  getID(name: string) {
    const params = { name };
    return this.http.get(`stilFacility/getID`);
  }
  getRecords(activeRecord: boolean, connectionStringName: string): Observable<any[]> {
    const params = {
      activeRecord,
      connectionStringName 
    };
    return this.http.get<any[]>(`stilFacility/getRecords`, { params: params as any });
  }
}
