import { Injectable } from '@angular/core';
import {
  State, StateBehavior, StateReplay 
} from '@devlime/ngx-state';
import { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { ServerConnectionStatus } from '../../../shared/models/serverconnectionstatus.model';
import { User } from '../../../shared/models/user.model';

export interface AppStateData {
  readonly activePermitType: string;
  readonly childPageTitle: string;
  readonly facility: any;
  readonly facilityId: number;
  readonly facilityName: string;
  readonly loading: boolean;
  readonly pageTitle: string;
  readonly permitGroupIds: Dictionary<number>;
  readonly permitTypeIcons: Dictionary<string>;
  readonly serverConnectionStatus: ServerConnectionStatus;
  readonly user: User;
  readonly showTopbar: boolean;
}

export type AppStateKey = keyof AppStateData;


@Injectable({
  providedIn: 'root'
})
export class AppState extends State<AppStateData> {

  @StateReplay<AppState, string>()
  private IANAFormat: string;
  readonly IANAFormat$: Observable<string>;

  @StateReplay<AppState, any>()
  private facility: any;
  readonly facility$: Observable<any>;

  @StateReplay<AppState, number>()
  private facilityId: number;
  readonly facilityId$: Observable<number>;

  @StateReplay<AppState, boolean>()
  private loading: boolean;
  readonly loading$: Observable<boolean>;

  @StateReplay<AppState, Dictionary<string>>()
  private permitTypeIcons: Dictionary<string>;
  readonly permitTypeIcons$: Observable<Dictionary<string>>;

  @StateReplay<AppState, Dictionary<number>>()
  private permitGroupIds: Dictionary<number>;
  readonly permitGroupIds$: Observable<Dictionary<number>>;

  @StateReplay<AppState, ServerConnectionStatus>()
  private serverConnectionStatus: ServerConnectionStatus;
  readonly serverConnectionStatus$: Observable<ServerConnectionStatus>;

  @StateReplay<AppState, string>()
  private activePermitType: string;
  readonly activePermitType$: Observable<string>;

  @StateReplay<AppState, string>()
  private facilityName: string;
  readonly facilityName$: Observable<string>;

  @StateReplay<AppState, string>()
  private pageTitle: string;
  readonly pageTitle$: Observable<string>;

  @StateReplay<AppState, string>()
  private childPageTitle: string;
  readonly childPageTitle$: Observable<string>;

  @StateReplay<AppState, User>()
  private user: User;
  readonly user$: Observable<User>;

  @StateBehavior<any, boolean>(true)
  private showTopbar: boolean;
  readonly showTopbar$: Observable<boolean>;

  constructor() {
    super();
  }

  set(prop: AppStateKey, value: AppState[AppStateKey]): void {
    super.set(prop, value);

    if (prop && value) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      sessionStorage.setItem(prop, value);
    }
  }
}
