import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth/auth.guard';
import { LoginGuard } from './auth/guards/auth/login.guard';
import { LoginComponent } from './auth/pages/login/login.component';
import { LoginPageResolve } from './auth/resolves/login/login.resolve';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { SidenavMenuComponent } from './layout/components/sidenav-menu/sidenav-menu.component';
import { DashboardComponent } from './layout/pages/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'portal',
    component: LayoutComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: DashboardComponent
          },
          {
            path: '',
            outlet: 'sidenav',
            component: SidenavMenuComponent
          }
        ]
      },
      {
        path: 'management',
        loadChildren: () => import('./management/management.module').then(m => m.ManagementModule) 
      },
      {
        path: 'db-manage',
        loadChildren: () => import('./db-manage/db-manage.module').then(m => m.DbManageModule) 
      },
      {
        path: 'worker-services',
        loadChildren: () => import('./worker-services/worker-services.module').then(m => m.WorkerServicesModule) 
      },
      {
        path: 'reports-upload',
        loadChildren: () => import('./reports-upload/reports-upload.module').then(m => m.ReportsUploadModule) 
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      loginPageData: LoginPageResolve
    }
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
