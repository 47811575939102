<div
  id="main"
  class="f-col start-start h-100 w-100 f-grow"
>
  <mat-toolbar
    id="top-bar"
    class="f-row nowrap space-between-center w-100"
  >
    <div class="mx-3 f-row start-center f-grow">
      <button
        mat-icon-button
        class="mr-3"
        (click)="sidenav.toggle()"
      >
        <mat-icon>{{ sidenav.opened ? 'menu_open' : 'menu' }}</mat-icon>
      </button>
    </div>

    <div class="px-3">
      <button
        class="btn btn-light"
        (click)="logout()"
      >
        Logout
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container
    id="content-container"
    class="f-col w-100 h-100"
  >
    <mat-sidenav
      #sidenav
      [mode]="sidenavMode$ | async"
      [opened]="true"
      [fixedInViewport]="mobileQuery$ | async"
      fixedTopGap="56"
    >
      <div
        id="static-nav"
        class="f-row nowrap center-center py-3 w-100 border-bottom border-dark"
        aria-label="Stilwell Portal - Home"
        routerLink="/portal"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
      >
        <div
          id="logo"
          class="mr-3"
        ></div>
        <span class="text-primary">Stilwell Portal</span>
      </div>

      <router-outlet name="sidenav"></router-outlet>
    </mat-sidenav>

    <mat-sidenav-content id="page-outlet">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
