import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { groupBy, keys } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class STILUserDataService {

  constructor(
    private http: HttpClient
  ) {}

  create(params, departments?) {
    console.log('USER PARAMS', params);
    const department = params.Department;
    if (department) {
      console.log('DEPs', groupBy(departments, 'Name'));
      params.DepartmentID = groupBy(departments, 'Name')[params.Department][0].ID;
      delete params.Department;
    }

    params.CreatedByUserID = JSON.parse(sessionStorage.getItem('user'))?.ID;
    params.ActiveRecord = true;
    params.isActiveDirectoryUser = false;
    params.IsTrained = false;
    return this.http.post(`stilUser/create`, params)
      .pipe(switchMap((res: any) => {
        res.Department = department;
        return of(res);
      }));
  }

  edit(params): Observable<any> {
    params.userID = params.Hidden_ID;

    for (const key of keys(params)) {
      if (params[key] == null) {
        params[key] = '';
      }
      if (key.includes('Hidden')) {
        const keyVal = params[key];
        const newKey = key.replace('Hidden_', '');
        params[newKey] = keyVal;
      }
    }

    params.LoggedIn = false;

    return this.http.put(`stilUser/edit`, params);
  }

  delete(userID: number) {
    const params = {
      userID,
      TableName: 'STIL:User' 
    };
    return this.http.delete(`stilUser/delete`, { params: params as any });
  }

  view(UserID: number, parentId: number): Observable<any> {
    const params = {
      UserID,
      parentId 
    };
    return this.http.get(`stilUser/view`, { params: params as any });
  }

  getAllAuthorizationsWithCheckBox(userID: number, getOnlyCheckedItems: boolean) {
    const params = {
      userID,
      getOnlyCheckedItems 
    };
    return this.http.get(`stilUser/getAllAuthorizationsWithCheckBox`, { params: params as any });
  }

  getAllSecurityGroupsWithCheckBox(userID: number, getOnlyCheckedItems: boolean) {
    const params = {
      userID,
      getOnlyCheckedItems 
    };
    return this.http.get(`stilUser/getAllSecurityGroupsWithCheckBox`, { params: params as any });
  }

  getAuthorizationsForUser(userID: number) {
    const params = { userID };
    return this.http.get('stilUser/getAuthorizationsForUser', { params: params as any });
  }

  getID(userName: string, isActiveDirectoryUser: boolean, parentID: number) {
    const params = {
      userName,
      isActiveDirectoryUser,
      parentID
    };
    return this.http.get(`stilUser/getID`, { params: params as any });
  }

  getUserIdByUserToken(token: string): Observable<number> {
    const params = new HttpParams({
      fromObject: {
        TableName: 'STIL:UserToken',
        token
      }
    });

    return this.http.get<number>('usertoken/getuseridbytoken', { params });
  }

  getRecords(parentID: number, activeRecord: boolean) {
    const params = {
      parentID,
      activeRecord 
    };
    return this.http.get(`stilUser/getAllForActiveRecord`, { params: params as any });
  }

  isValidPassword(userID: number, unencryptedPassword: string, facilityID: number): Observable<boolean> {
    const params = {
      userID,
      unencryptedPassword,
      facilityID
    };
    return this.http.get<boolean>(`stilUser/isValidPassword`, { params: params as any });
  }

  resetPassword(userID: number, password: string) {
    const params = {
      userID,
      password 
    };
    return this.http.put<boolean>(`stilUser/updatepassword`, params);
  }
}
