<div class="f-row wrap w-100">
  <h3
    mat-dialog-title
    class="w-100"
  >
    Reset Password
  </h3>

  <mat-dialog-content
    class="f-row wrap center -start w-100 m-0 p-2"
    [formGroup]="passwordForm"
  >
    <label
      for="password"
      class="form-label w-100"
    >
      Password
    </label>
    <div class="input-group w-100 mb-3">
      <input
        [type]="getInputType(showPassword)"
        name="password"
        formControlName="password"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          class="show-btn btn btn-outline-secondary"
          type="button"
          (click)="toggleShowPassword()"
        >
          <i [class]="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
        </button>
      </div>
    </div>

    <label
      for="passwordVerify"
      class="form-label w-100"
    >
      Confirm Password
    </label>
    <div class="input-group mb-3">
      <input
        [type]="getInputType(showPasswordVerify)"
        name="passwordVerify"
        formControlName="passwordVerify"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          class="show-btn btn btn-outline-secondary"
          type="button"
          (click)="toggleShowPasswordVerify()"
        >
          <i [class]="['fas', showPasswordVerify ? 'fa-eye-slash' : 'fa-eye']"></i>
        </button>
      </div>
    </div>

    <div
      id="password-criteria"
      class="f-row wrap start-center m-3 w-100"
    >
      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(passwordsMatch)">
          {{ getPasswordCriteriaIcon(passwordsMatch) }}
        </mat-icon>
        <div [class]="getPasswordCriteriaClass(passwordsMatch)">Passwords Match</div>
      </div>

      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(minLength)">{{ getPasswordCriteriaIcon(minLength) }}</mat-icon>
        <div [class]="getPasswordCriteriaClass(minLength)">Minimum of 8 characters in length</div>
      </div>

      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(maxLength)">{{ getPasswordCriteriaIcon(maxLength) }}</mat-icon>
        <div [class]="getPasswordCriteriaClass(maxLength)">Maximum of 35 characters in length</div>
      </div>

      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(upperAndLowerCase)">
          {{ getPasswordCriteriaIcon(upperAndLowerCase) }}
        </mat-icon>
        <div [class]="getPasswordCriteriaClass(upperAndLowerCase)">
          Contains both upper and lowercase letters (e.g. A-Z, a-z)
        </div>
      </div>

      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(containsNumber)">
          {{ getPasswordCriteriaIcon(containsNumber) }}
        </mat-icon>
        <div [class]="getPasswordCriteriaClass(containsNumber)">Contains at least one number (e.g. 0-9)</div>
      </div>

      <div class="criteria f-row start-center p-2 w-100">
        <mat-icon [class]="getPasswordCriteriaClass(containsSpecialCharacter)">
          {{ getPasswordCriteriaIcon(containsSpecialCharacter) }}
        </mat-icon>
        <div [class]="getPasswordCriteriaClass(containsSpecialCharacter)">
          Contains at least one special character (e.g. ~!@#$%^&*()_-+=)
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="f-row end-center w-100">
    <button
      class="btn btn-sm btn-warning m-2"
      tabindex="-1"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      class="btn btn-sm btn-primary m-2"
      tabindex="-1"
      (click)="resetPassword()"
      [disabled]="resetDisabled"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</div>
