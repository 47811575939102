import {
  Component, Inject, OnInit 
} from '@angular/core';
import {
  AbstractControl, FormBuilder, FormGroup, Validators 
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { AppState } from '~core/states/app/app.state';
import { STILUserDataService } from '../../shared/services/apiSTILUserController';

@Component({
  selector: 'app-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: [ './password-reset-modal.component.scss' ]
})
export class PasswordResetModalComponent implements OnInit {

  passwordForm: FormGroup;

  showPassword = false;

  showPasswordVerify = false;

  get containsNumber(): boolean {
    return !!this.passwordCtrl.value.match(/[0-9]/g);
  }

  get containsSpecialCharacter(): boolean {
    return !!this.passwordCtrl.value.match(/[^A-Za-z 0-9]/g);
  }

  get maxLength(): boolean {
    return this.passwordCtrl.value.length < 35;
  }

  get minLength(): boolean {
    return this.passwordCtrl.value.length > 7;
  }

  get passwordCtrl(): AbstractControl {
    return this.passwordForm.get('password');
  }

  get passwordVerifyCtrl(): AbstractControl {
    return this.passwordForm.get('passwordVerify');
  }

  get passwordsMatch(): boolean {
    return this.passwordCtrl.value === this.passwordVerifyCtrl.value;
  }

  get resetDisabled(): boolean {
    return this.passwordForm.invalid || !this.passwordsMatch;
  }

  get upperAndLowerCase(): boolean {
    return this.passwordCtrl.value.match(/[a-z]/g) && this.passwordCtrl.value.match(/[A-Z]/g);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private userSvc: STILUserDataService,
    private appState: AppState,
    private matDialogRef: MatDialogRef<PasswordResetModalComponent>,
    private toast: HotToastService
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      password: [ '', Validators.required ],
      passwordVerify: [ '', Validators.required ]
    });
  }

  getInputType(show: boolean): string {
    return show ? 'text' : 'password';
  }

  getPasswordCriteriaClass(criteria: boolean): string {
    const colorClass = criteria ? 'text-success' : 'text-danger';
    return `mr-4 ${colorClass}`;
  }

  getPasswordCriteriaIcon(criteria: boolean): string {
    return criteria ? 'check' : 'report';
  }

  resetPassword() {
    this.userSvc.resetPassword(this.appState.get('user').ID, this.passwordCtrl.value)

      .subscribe(
        res => {
          this.toast.success('Password successfully reset');
          this.matDialogRef.close();
        },
        err => this.toast.error('Password reset failed')
      );
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowPasswordVerify(): void {
    this.showPasswordVerify = !this.showPasswordVerify;
  }

}
