import { Injectable } from '@angular/core';
import { State, StateReplay } from '@devlime/ngx-state';
import { Dictionary } from 'lodash';
import { Observable } from 'rxjs';

export interface FacilityData {
  readonly dashboardIcons: Dictionary<string>;
  readonly generalSettings: Dictionary<any>;
}

@Injectable({
  providedIn: 'root'
})
export class FacilityState extends State<FacilityData> {

  @StateReplay<FacilityState, Dictionary<string>>()
  private dashboardIcons: Dictionary<string>;
  readonly dashboardIcons$: Observable<Dictionary<string>>;

  @StateReplay<FacilityState, Dictionary<any>>()
  private generalSettings: Dictionary<any>;
  readonly generalSettings$: Observable<Dictionary<any>>;

  constructor() {
    super();
  }
}
