import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';


export const MY_NATIVE_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23'
  },
  datePickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric' 
  },
  timePickerInput: {
    hour: 'numeric',
    minute: 'numeric' 
  },
  monthYearLabel: {
    year: 'numeric',
    month: 'short' 
  },
  dateA11yLabel: {
    year: 'numeric',
    month: 'long',
    day: 'numeric' 
  },
  monthYearA11yLabel: {
    year: 'numeric',
    month: 'long' 
  }
};

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [],
  exports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule
  ],
  providers: []
})
export class SharedModule {}
