import {
  Component, Inject, OnInit, Optional 
} from '@angular/core';
import { SidenavLinksProvider } from '~core/tokens/sidenav-links';
import { MAIN_NAV_LINKS } from '../../config/nav-links';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {

  constructor(@Optional() @Inject(SidenavLinksProvider) public links: any[]) { }

  ngOnInit(): void {
    if (!this.links) {
      this.links = MAIN_NAV_LINKS;
    }
  }

}
