import {
  NgModule, APP_INITIALIZER, Injector 
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HotToastModule } from '@ngneat/hot-toast';

import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { InitializeLoading } from '~core/services/loading/loading.service';
import { AppService, InitializeAppState } from '~core/services/app/app.service';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    CoreModule,
    LayoutModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpCacheInterceptorModule.forRoot({ ttl: 150000 }),
    HotToastModule.forRoot()
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeLoading,
      deps: [ Injector ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeAppState,
      deps: [ AppService ],
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
