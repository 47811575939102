
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class STILGlobalDataDataService {
  constructor(private http: HttpClient) {}

  getConnectionStringList(): Observable<any[]> {
    const params = {
      applicationName: 'Portal'
    };
    return this.http.get<any[]>(`stilGlobalData/getConnectionStringList`, { params });
  }

  getColumnsOfTable(tableName: string) {
    const params = { tableName };
    return this.http.get(`stilGlobalData/getColumnsOfTable`, { params: params as any });
  }

  getDistinctColumnValues(sourceData: any, columnName: string) {
    const params = {
      sourceData,
      columnName 
    };
    return this.http.get(`stilGlobalData/getDistinctColumnValues`, { params: params as any });
  }
}
